<template>
  <div class="create-content-main">
    <div class="list-note background-content-admin">
      <h2 class="font-title font-weight-bold">ノート確認：NO.{{ NO }}</h2>
      <div class="list-note-content note-content-admin">
        <h5 class="font-weight-bold font-title-default">{{ title }}</h5>
        <div
          class="title-content-list"
          v-for="(item, index) in dataNote"
          :key="index"
        >
          <div class="title-content-list-item">
            <div class="header-item justify-content-between row">
              <div class="header-item-title col-md-6 col-sm-12">
                {{ item.title_note }}
              </div>
              <div
                class="header-item-time header-time text-nowrap col-md-6 col-sm-12"
              >
                {{
                  new Date(item.created_at).toLocaleString("ja-JP", {
                    timeZone: "Asia/Tokyo",
                  })
                }}
              </div>
            </div>
            <div class="content-item customContentNote">
              <div v-html="item.content_note"></div>
            </div>
            <div class="content-item item-info">
              PDF： {{ item.page_pdf }}枚目
            </div>
            <div class="content-item item-info">
              動画： {{ item.seconds_watch_video }}秒
            </div>
            <div class="list-tags d-flex flex-wrap">
              <div
                class="position-relative d-flex align-items-center px-1 py-1"
                v-for="(element, indexs) in item.tags"
                :key="indexs"
              >
                <a class="customA"
                  ><span class="customSpan">{{ element.tag_name }}</span></a
                ><img
                  src="@/assets/img/bg_note_tag_active.png"
                  style="width: 18px; height: 20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex my-5">
        <b-button
          type="submit"
          class="mr-4"
          v-on:click.prevent="returnUserNote()"
          >一覧に戻る</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListNoteByContent",
  created() {
    const formData = {
      shop_id: this.shop_id,
      user_id: this.$route.params.idUser,
      content_id: this.$route.params.idContent,
    };
    this.getListNoteByContentAdmin(formData);
  },
  data() {
    return {
      dataNote: [],
      title: "",
      NO: this.$route.params.no,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  computed: {
    ...mapGetters(["ListNoteByContent", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    ListNoteByContent() {
      this.title = this.ListNoteByContent.content_title;
      if (this.ListNoteByContent.notes) {
        this.ListNoteByContent.notes.map((item) => {
          this.dataNote.push(item);
        });
      }
    },
  },
  methods: {
    ...mapActions({ getListNoteByContentAdmin: "getListNoteByContentAdmin" }),
    returnUserNote() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "ListUserNote",
          params: {
            shopId: this.shop_id,
            id: this.$route.params.idUser,
          },
        });
      } else {
        this.$router.push({
          name: "ListUserNote domain",
          params: {
            id: this.$route.params.idUser,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-note {
  padding: 50px;
  &-title {
    font-size: 24px;
    font-weight: 600;
  }
  &-content {
    margin-top: 30px;
    .title-mark {
      font-size: 18px;
    }
    .title-content {
      font-size: 22px;
      color: #4a4a4a;
      margin: 10px 0;
    }
    .title-content-list {
      &-item {
        border: 2px solid #4a4a4a;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 30px;
        .header-item {
          padding: 0 50px 0 30px;
          &-title {
            font-size: 20px;
            color: #4a4a4a;
            font-weight: 600;
          }
          &-time {
            font-size: 16px;
            color: #000;
          }
        }
        .content-item {
          padding: 0 50px;
          margin: 20px 0;
          font-size: 16px;
        }
        @media (max-width: 420px) {
          .header-item {
            padding: 0;
          }
          .content-item {
            padding: 0;
          }
        }
        .list-tags {
          &-item {
            border: 2px solid saddlebrown;
            background: orangered;
            padding: 5px 20px;
            border-radius: 10px;
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
